.toast-container {
  width: calc(30px + 2rem);
  height: calc(30px + 2rem);
  padding: 0;
  min-height: 0;
  background: transparent;
  box-shadow: none;
  margin-bottom: 0.2rem;
}

$gradient-light: rgba(0,85,141,1);
$gradient-dark: rgb(0,12,33);

.toast-body {
  width: 50px;
  height: 50px;
  flex: none;
  background: $gradient-dark;
  //background: radial-gradient(circle, $gradient-dark 20%, $gradient-light 100%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $gradient-light;
}

.toast-body svg {
  width: 35px;
  height: 35px;
}

.Toastify__toast-container--bottom-left {
  left: .55rem;
  bottom: 100px;
}
