#chat-form {
  margin-left: .5rem;
}

.chat_message__wrapper {
  padding: 0.3rem 1rem;
  margin-bottom: 0.5rem;
  max-width: 30rem;
  background: white;
  border: #9a9a9a 1px solid;
  border-radius: 10px 10px 10px 0;
}

.chat_message__wrapper.owner {
  margin-left: auto;
  border-radius: 10px 10px 0px 10px;
}

.chat_message__header {
  display: flex;
  justify-content: space-between;
}

.chat_message__header span:first-child {
  font-weight: bold;
}

.chat_message__header span:last-child {
  font-weight: lighter;
  color: #5a5a5a;
  font-size: smaller;
}

.chat_message__text {
  color: #000;
}
